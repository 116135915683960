import React from "react"
import PropTypes from "prop-types"
import { map } from "lodash"
import useCloudinary from "components/hooks/useCloudinaryImage"
import { useCustomDevice } from "components/hooks/useCustomDevice"
import useWindow from "components/hooks/useWindow"
import { useHistory } from "react-router-dom"
import LazyLoad from "react-lazyload"
function ShortContent({ data }) {
  const shortContentData = data?.shortContents
  const { isMobile } = useWindow()
  const history = useHistory()

  const web = (
    <div
      className="shortContent"
      style={{
        minHeight: 200,
        backgroundColor: data?.bgColor ? data?.bgColor : "#007551",
        cursor: data?.link ? "pointer" : "default",
      }}
      onClick={() => (data?.link ? history.push(`${data?.link}`) : "")}
    >
      <div className="d-flex align-items-center justify-content-center px-1 shortContentInner row w-100">
        {map(shortContentData, (short, key) => (
          <div
            className="d-flex-center-center px-4 col-lg-4 col-12 content"
            key={key}
          >
            <LazyLoad height={200}>
              <img
                src={useCloudinary(
                  short?.image?.url ?? "",
                  isMobile ? "w_300" : "w_300,q_60"
                )}
                className="me-3"
                width={100}
                height={100}
                alt="short-content"
                loading="lazy"
              />
            </LazyLoad>
            <h4 style={{ color: data?.fgColor ? data?.fgColor : "#ffffff" }}>
              {short?.title}
            </h4>
          </div>
        ))}
      </div>
    </div>
  )

  const mobile = web

  return useCustomDevice(data?.platform, mobile, web)
}

export default ShortContent

ShortContent.propTypes = {
  data: PropTypes.any,
}
