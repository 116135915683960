import { map } from "lodash"
import React, { useEffect, useState } from "react"
import { useLocation, useParams } from "react-router-dom"
import Highlight from "./components/Highlight"
import ShortContent from "./components/ShortContent"
import Video from "./components/VideoBanner"
import WideContent from "./components/WideContent"
import Header1 from "./static/Header"
import ImageGrid from "./components/ImageGrid"
import FaqData from "./Extra/FAQ"
import Testimonials from "./Extra/CustomerReview"
import Footer from "./static/Footer"
import { useDispatch, useSelector } from "react-redux"
import { getLayoutPages, getPagesHome } from "store/actions"
import InfiniteScroll from "react-infinite-scroll-component"
import GroupLoader from "components/Common/loaders/GroupLoader"
import ProductVariant from "./components/ProductVarient"
import Carousel from "./components/Carousel"
import useWindow from "components/hooks/useWindow"
import { useArrowToSpace } from "components/hooks/useArrowToSpace"
import MetaData from "util/MetaData"
import useMetaHandler from "components/hooks/useMetaHandler"
import Preloader from "components/elements/Preloader"
import PropTypes from "prop-types"

function LandingPage({ isAttachedPage }) {
  const dispatch = useDispatch()
  const params = useParams()
  const location = useLocation()
  const { isMobile } = useWindow()

  const { layoutPages, loading, siteSettings, homePage } = useSelector(
    state => ({
      layoutPages: state.LayoutPages.layoutPages,
      loading: state.LayoutPages.loading,
      siteSettings: state?.SiteSettings.siteSettings,
      homePage: state?.LayoutPages.homePage,
    })
  )

  const productName = useArrowToSpace(params?.name)

  const [sections, setSections] = useState([])

  useEffect(() => {
    if (siteSettings?.setup?.isCustomHomePage && location.pathname === "/") {
      return setSections(homePage?.sections)
    } else return setSections(layoutPages?.sections)
  }, [layoutPages, homePage])

  const handlePageData = () => {
    if (siteSettings?.setup?.isCustomHomePage && location.pathname === "/") {
      return homePage
    } else return layoutPages
  }

  useEffect(() => {
    if (siteSettings?.setup?.isCustomHomePage && location.pathname === "/") {
      dispatch(getPagesHome())
    }
  }, [siteSettings?.setup])

  useEffect(() => {
    if (params.name && !isAttachedPage) {
      dispatch(getLayoutPages(productName))
    }
  }, [params.name])

  const WebPages = {
    Carousel,
    Highlight,
    ShortContent,
    WideContent,
    ImageGrid,
    Video,
    ProductVariant,
    Testimonials,
    FaqData,
  }

  const HandleSections = section => {
    if (section?.layout?.length >= 1) {
      const finalLayout =
        section?.layout?.charAt(0)?.toUpperCase() + section?.layout.slice(1)
      const PageComponent = WebPages[finalLayout]
      return <PageComponent data={section} />
    }
  }

  const handleSectionHeight = height => {
    if (height !== null) {
      return height + "vh"
    } else return "fit-content"
  }

  const product = handlePageData()?.product

  const {
    metaDescription,
    metaKeyword,
    metaTitle,
    canonicalLink,
    metaProduct,
  } = useMetaHandler(
    handlePageData()?.title,
    handlePageData()?.description,
    product
  )

  return (
    <>
      {!isAttachedPage && (
        <MetaData
          content={handlePageData()?.title}
          title={metaTitle}
          description={metaDescription}
          keyword={handlePageData()?.keywords}
          canonicalLink={canonicalLink}
          ogImage={handlePageData()?.image?.url}
          product={metaProduct}
        />
      )}
      {loading ? (
        <Preloader />
      ) : (
        <div className="overflow-hidden position-relative">
          {!isAttachedPage && <Header1 />}
          <InfiniteScroll
            scrollThreshold={0.6}
            dataLength={sections?.length ?? 0}
            // next={HandleFetchNextPage}
            hasMore={true}
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
            loader={
              loading && (
                <div className="container m-auto mobileGroup">
                  <GroupLoader count={4} mobileCount={2} myWidth={"100%"} />
                </div>
              )
            }
          >
            {sections?.length >= 1 && (
              <div className="pt-4">
                {map(sections, (section, key) => (
                  <div
                    className={`section-wrapper ${section?.platform} sm:m-0  ${
                      key === 0 && !isMobile ? "h-calc" : ""
                    }`}
                    style={{
                      height: isMobile
                        ? "fit-content"
                        : handleSectionHeight(section?.height),
                    }}
                    key={key}
                  >
                    {HandleSections(section)}
                  </div>
                ))}
              </div>
            )}
          </InfiniteScroll>
        </div>
      )}
      {!isAttachedPage && <Footer />}
    </>
  )
}

export default LandingPage

LandingPage.propTypes = {
  isAttachedPage: PropTypes.bool,
}
